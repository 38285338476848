* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
		'Open Sans', 'Helvetica Neue', sans-serif;
	background: rgb(255, 255, 255);
	display: flex;
	justify-content: center;
}
/* width */
::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #ffffff;
	border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(180, 180, 180);
	border-radius: 2px;
}
pre {
	font-family: inherit;
	font-size: inherit;
	white-space: pre-wrap;
}

/*************************************************************************************************/

#header {
	font-size: 16px;
	font-weight: 500;
	position: fixed;
	top: 0;
	left: 160px;
	right: 0;
	background: rgb(255, 255, 255);
	height: 55px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: rgb(86, 109, 124);
	border-bottom: thin solid rgb(86, 109, 124);
}
#login_area {
	right: 0;
	border-left: thin solid rgb(86, 109, 124);
	width: 270px;
	/* min-width: 300px; */
	height: 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
#name-of-dr {
	margin: 0 20px 0 10px;
}
#toolbar {
	margin-right: 0px;
	margin-left: 0px;
	width: 35%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-right: 10px;
}

#toolbar .logout-btn {
	width: fit-content;
}
#toolbar .logout-btn button {
	padding: 5px 10px 5px 10px;

	cursor: pointer;
	background: rgb(86, 109, 124);
	color: white;
	border: none;
}
#toolbar .logout-btn button:hover {
	background: rgb(108, 156, 165);
}

/*************************************************************************************************/

#navbar_container {
	font-size: 16px;
	font-weight: 500;
	background: rgb(81, 121, 128);
	width: 160px;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

#navbar_header {
	min-height: 55px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: thin solid rgb(30, 75, 105);
}
#navbar_header-img {
	/* padding: 2px; */
	height: auto;
	width: 80%;
}

.navbar_buttons {
	margin-top: 10px;
	width: 100%;
}
.navbar_buttons a {
	color: inherit; /* blue colors for links too */
	text-decoration: inherit; /* no underline */
	width: 100%;
	text-align: left;
}

.nav_btn {
	cursor: pointer;
	width: 100%;
	height: fit-content;
	display: flex;
	align-items: center;
	justify-content: baseline;
	padding: 6px 10px 6px 15px;
	color: rgb(255, 255, 255);
}
.nav_btn:hover {
	/* border-left: 5px solid rgb(108, 156, 165); */
	background: rgb(108, 156, 165);
}

.nav_btn.active {
	color: rgb(86, 109, 124);
	background: white;
	cursor: default;
}
#doctors_btn.nav_btn.active {
	border-left: 6px solid #9d949d;
}
#patients_btn.nav_btn.active {
	border-left: 6px solid #c4a39f;
}

.nav_btn i {
	width: 23%;
}

.nav-info_div {
	margin-top: 5px;
}

.nav-info {
	display: flex;
	align-items: center;
	padding-left: 20px;
	color: rgb(86, 109, 124);
	background: white;
	cursor: default;
	font-size: 14px;
	font-weight: 400;
	height: 0;
	/* opacity: 0.5; */
	overflow: hidden;
	border-left: 6px solid rgb(86, 109, 124);
	transition: height 0.3s ease-in-out;
	/* transition: opacity 0.05s linear; */
}
.nav-info.active {
	height: 30px;
	/* opacity: 1; */
}

/*************************************************************************************************/

#content {
	font-size: 14px;
	position: fixed;
	top: 55px;
	bottom: 0px;
	left: 160px;
	right: 0px;
	min-width: 800px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.loader_div {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-top: 100px;
	text-align: center;
}
.loader_div img {
	height: 60px;
	width: auto;
}

/*************************************************************************************************/

.grid_div,
.appointment_div,
.profile_div,
.form_div,
.image-update_div {
	position: fixed;
	top: 55px;
	left: 160px;
	right: 0;
	bottom: 0;
	padding: 0 10px;
	/* display: flex;
	flex-direction: column; */
}
.appointment_div,
.image-update_div {
	overflow-y: scroll;
}

.sign-in-up_div {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-top: 200px;
}

/*************************************************************************************************/

.grid-header {
	position: fixed;
	top: 55px;
	left: 160px;
	right: 0;
	height: 80px;
	padding: 0 10px;
	z-index: 1;
	background: white;
}
#search-input_div {
	width: 100%;
	height: 33px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 7px 0 0 4px;
}
#search-input_div .form-group {
	width: 27.4%;
}
#search-input_div .form-group,
#search-input_div .form-control,
#search-input_div .form-label {
	margin: 0;
}

/* #search-input_div input::placeholder { */
/* color: gray; */
/* font-style: italic; */
/* } */

.add_person_btn a {
	color: rgb(86, 109, 124); /* bluecolors for links too */
	text-decoration: inherit; /* no underline */
	/* background: rgb(86, 109, 124); */
	/* padding: 3.5px 20px; */
	/* border-radius: 5px; */
}
.add_person_btn {
	/* margin-left: 4.7%; */
	margin-left: 2%;
	font-size: 22px;
}

#table_header {
	display: flex;
	align-items: center;
	height: 40px;
	padding: 6px 3px;
	border-bottom: thin solid rgb(30, 75, 105);
}

.table_row-div {
	display: flex;
	align-items: center;
	height: 35px;
	padding: 6px 3px;
}

.table_row-div:hover .table_row-col {
	background: rgb(214, 214, 214);
	cursor: pointer;
}
.table_row-div:hover .table_row-btn {
	background: rgb(255, 255, 255);
	cursor: pointer;
}
.table_row-div:hover .table_row-btn i {
	color: rgb(108, 156, 165);
	cursor: pointer;
}

.doctor-list .table_header-col {
	background: #9d949d;
	border: solid thin #9d949d;
}
.patient-list .table_header-col {
	background: #c4a39f;
	border: solid thin #c4a39f;
}
.table_header-col {
	height: 27px;
	width: 22.5%;
	display: flex;
	align-items: center;
	color: white;
	font-weight: 700;
	margin: 0 1px;
	padding: 0 5px;
}
.table_row-col {
	height: 27px;
	width: 22.5%;
	display: flex;
	align-items: center;
	color: black;
	/* font-weight: 700; */
	margin: 0 1px;
	padding: 0 5px;
	border: solid thin gray;
}

.table_row-col.thin-col {
	width: 5%;
	justify-content: center;
}
.table_header-col.thin-col {
	width: 5%;
	justify-content: center;
}
.thin-col.table_header-btn {
	background: none;
	border: none;
}
.thin-col.table_row-btn {
	justify-content: flex-end;
	padding-right: 1%;
	background: none;
	border: none;
}

.table_header-btn a {
	color: rgb(86, 109, 124); /* bluecolors for links too */
	text-decoration: inherit; /* no underline */
	cursor: pointer;
}

.table_header-btn i,
.table_row-btn i {
	color: rgb(86, 109, 124);
	font-size: 24px;
	cursor: pointer;
}
.table_header-btn .fa-plus {
	font-size: 18px;
	margin-right: 2px;
}
.table_header-btn i:hover,
.table_row-btn i:hover {
	color: rgb(108, 156, 165);
}
.table_header-btn a:hover i {
	color: rgb(108, 156, 165);
}

#table_header tr:hover {
	background: transparent;
}

.grid-body {
	position: fixed;
	top: 135px;
	left: 160px;
	right: 0;
	bottom: 0;
	padding: 0 3px 10px 10px;
	overflow-y: scroll;
}

.table_row {
	margin: 0;
}
.table_row table tr {
	cursor: pointer;
}
table {
	width: 100%;
	table-layout: fixed;
	/* border-collapse: collapse; */
	padding: 2px;
}
table tr:hover {
	background: rgb(214, 214, 214);
}
table td {
	border: thin solid gray;
	height: 26px;
	padding: 2px 0 2px 5px;
	text-align: left;
}
table th {
	height: 26px;
	padding: 2px 0 2px 5px;
	color: rgb(255, 255, 255);
	background: rgb(108, 156, 165);
	border: thin solid rgb(108, 156, 165);
	text-align: left;
}

.doctor-list table th {
	background: #9d949d;
	border: thin solid #9d949d;
}

.patient-list table th {
	background: #c4a39f;
	border: thin solid #c4a39f;
}

.doctor-list .table_btn,
.patient-list .table_btn {
	background: none;
	border: thin solid rgb(255, 255, 255);
}

.empty_th {
	border-bottom: none;
	background: none;
	visibility: hidden;
}

th.thin-column,
td.thin-column {
	width: 5%;
	text-align: center;
	padding: 0;
}

.table_btn {
	font-weight: 400;
	text-align: center;
	background: rgb(255, 255, 255);
	border: thin solid rgb(255, 255, 255);
}
.table_btn i {
	font-size: 24px;
	color: rgb(86, 109, 124);
	cursor: pointer;
}
.table_btn .fa-plus {
	font-size: 18px;
	margin-right: 2px;
}
.table_btn i:hover {
	color: rgb(108, 156, 165);
}
.table_btn a:hover i {
	color: rgb(108, 156, 165);
}

/*************************************************************************************************/

.form-container {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 10px 20px 20px 20px;
}

.form-container.images.hidden {
	display: none;
}
.form-container.images .form-group {
	width: 100%;
}

.form-container.images .form-group .btn {
	margin-top: 10px;
}

.filepond--root {
	width: 950px;
}
.filepond--item {
	width: calc(25% - 0.5em);
}

.naslov {
	width: 100%;
	font-size: 16px;
	font-weight: 600;
	text-align: left;
	margin: 5px 0 10px 20px;
}

.add-patient.naslov {
	background: #c4a39f;
}
.add-doctor.naslov {
	background: #9d949d;
}
.add-appointment.naslov {
	background: rgb(155, 155, 155);
}
.add-patient.naslov,
.add-doctor.naslov,
.add-appointment.naslov {
	border-radius: 3px;
	color: white;
	padding: 5px 0 5px 10px;
	margin: 5px 5px 10px;
}

.add-patient.naslov i,
.add-doctor.naslov i {
	margin: 0 2px;
}

.form-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: 8px 0.5% 0px 0.5%;
	width: 24%;
}

.form-group.notes {
	width: 100%;
	font-family: inherit;
}
.sec-btn,
.cancel-btn {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.sec-btn .form-group {
	padding: 3px;
}
.sec-btn.hdn,
.cancel-btn.hdn {
	display: none;
}

.form-group label {
	font-size: 14px;
	margin: 6px 0 4px 10px;
}
.form-group input,
.form-group select {
	font-family: inherit;
	font-size: 14px;
	width: 100%;
	height: 26px;
	border: thin solid rgb(184, 184, 184);
	background: white;
	border-radius: 5px;
	padding: 0px 4px 0px 9px;
}
.form-group input:-webkit-autofill,
.form-group input:-webkit-autofill:focus,
.form-group input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px white inset !important;
}
.form-group input::placeholder {
	color: rgb(194, 194, 194);
	font-size: inherit;
}
.form-group input:required,
.form-group select:required {
	border: thin solid rgb(255, 70, 70);
}
.form-group input:required:focus,
.form-group select:required:focus {
	outline: none;
	border: 2px solid rgb(255, 70, 70);
}

.form-group input:optional:focus,
.form-group select:optional:focus {
	outline: none;
	border: 2px solid rgb(145, 145, 145);
}

.checkbox {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	height: 26px;
	padding-bottom: 2px;
	border: thin solid rgb(255, 70, 70);
	border-radius: 5px;
}
.checkbox.check {
	border: thin solid rgb(184, 184, 184);
}
.checkbox.mustcheck {
	border: 2px solid rgb(255, 70, 70);
}
.checkbox label {
	margin: 0;
}
.checkbox-inline {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 20%;
}
.checkbox-inline .treatments {
	height: 14px;
	width: 14px;
	margin: 2px 5px 0 10px;
}

textarea.form-control {
	font-family: inherit;
	width: 100%;
	padding: 5px 10px 5px 10px;
	margin: 0;
	border: thin solid rgb(184, 184, 184);
	border-radius: 5px;
}

#image.form-control {
	padding: 10px;
	height: fit-content;
	min-height: 120px;
	border: thin dashed rgb(184, 184, 184);
}

.form-group .btn-primary,
.form-group .btn-secondary {
	margin-top: 29px;
	height: 26px;
	font-size: 14px;
	cursor: pointer;
	background: rgb(86, 109, 124);
	border: thin solid rgb(86, 109, 124);
	color: white;
	width: 100%;
}

.form-group .btn-primary:hover,
.form-group .btn-secondary:hover {
	background: rgb(108, 156, 165);
	border: thin solid rgb(108, 156, 165);
}
.form-group .btn-primary:disabled,
.form-group .btn-secondary:disabled,
.form-group .btn-primary:disabled:hover,
.form-group .btn-secondary:disabled:hover {
	background: rgb(128, 128, 128);
	border: thin solid rgb(128, 128, 128);
	cursor: default;
}

.form-group input:disabled,
.form-group select:disabled,
.form-group textarea:disabled {
	border: thin solid rgb(184, 184, 184);
	color: black;
	background: white;
}
.form-group input:valid,
.form-group select:valid,
.form-group textarea:valid {
	border: thin solid rgb(184, 184, 184);
	color: black;
	background: white;
}
.form-group select:disabled {
	-moz-appearance: none;
	-webkit-appearance: none;
}

#content > form.form-container.images > div.btn_div > div {
	width: 24%;
}

.btn_div {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.loader-btn_div {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 20px;
}
.add-person-btn_div .loader-btn_div {
	justify-content: center;
	padding: 30px 0 10px 0;
}
.loader-btn_div img {
	height: 40px;
	width: auto;
}
.add-person-btn_div .loader-btn_div img {
	height: 26px;
	width: auto;
}

.loader-btn_div.hdn,
.btn_div.hdn {
	display: none;
}

.add-person-btn_div {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin: 8px 0.5% 0px 0.5%;
	width: 24%;
}
.add-person-btn_div .form-group {
	width: 50%;
	margin: 0 3px;
}
/*************************************************************************************************/

.sign-container {
	width: 40%;
	display: flex;
	flex-direction: column;
}
.sign-container .naslov {
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: row;
	width: 100%;
	padding-bottom: 15px;
	border: thin solid rgb(86, 109, 124);
	border-bottom: none;
	margin: 0;
}
.sign-container .naslov .link {
	text-align: center;
	background: rgb(86, 109, 124);
	color: white;
	padding: 5px;
	font-weight: 400;
	font-size: 16px;
}
.sign-container .naslov .link:hover {
	background: rgb(108, 156, 165);
}
.sign-container .naslov .active-link {
	text-align: center;
	padding: 5px;
	cursor: default;
	font-weight: 400;
	font-size: 16px;
}

.sign-container .sign-form_div {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	border: thin solid rgb(86, 109, 124);
	border-top: none;
}
.sign-container .form-group {
	width: 90%;
	padding: 10px 0 0 0;
	margin: 0;
}
.sign-pass_div {
	width: 90%;
	display: flex;
	flex-direction: row;
}
.sign-pass_div .form-group {
	width: 93%;
}
.sign-pass_div span {
	align-self: flex-end;
	width: 7%;
	height: 27px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgb(86, 109, 124);
	cursor: pointer;
}
.signup-pass_div {
	width: 90%;
}
.signup-pass_div .sign-pass_div {
	width: 100%;
	display: flex;
	flex-direction: row;
}
.signup-pass_div .form-group {
	width: 100%;
}
div.sign-form_div > div.signup-pass_div > div:nth-child(2) > div {
	padding: 5px 0 0;
}

.sign-container .btn {
	margin-bottom: 25px;
}
.sign-container .btn:hover {
	background: rgb(108, 156, 165);
}
.sign-container a {
	color: inherit; /* blue colors for links too */
	text-decoration: inherit; /* no underline */
	width: 100%;
	text-align: left;
}

/*************************************************************************************************/

.personal-details-background {
	background: rgb(255, 255, 255);
	padding: 10px;
	position: fixed;
	top: 55px;
	right: 0;
	height: 91px;
	left: 160px;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
}
.personal-details {
	display: flex;
	flex-direction: column;
	border-radius: 30px;
	border-top-right-radius: 0;
	border-bottom-left-radius: 0;
	height: 71px;
	max-height: 71px;
	padding: 7px 9px 7px 12px;
	width: 100%;
	color: white;
	animation: transit 0.4s ease-out;
}
@keyframes transit {
	0% {
		opacity: 0.8;
	}
	100% {
		opacity: 1;
	}
}

.personal-details.loading-personal {
	width: 100%;
	height: 71px;
	opacity: 0.7;
	animation: loader 3s infinite ease-out;
}
@keyframes loader {
	10% {
		border-radius: 30px;
		border-top-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	15% {
		border-radius: 30px;
	}
	35% {
		border-radius: 30px;
		border-top-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}
	40% {
		border-radius: 30px;
	}
	60% {
		border-radius: 30px;
		border-top-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	65% {
		border-radius: 30px;
	}
	85% {
		border-radius: 30px;
		border-top-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}
	90% {
		border-radius: 30px;
	}
}

.personal-details.doctor-profile {
	background: linear-gradient(to left, #9d949d, #766a76);
}

.personal-details.patient-profile {
	background: linear-gradient(to left, #c4a39f, #997f7c);
}

.personal-details-top {
	height: 28px;
	display: flex;
}
.personal-details-bottom {
	width: 90%;
	height: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.personal-details-name {
	display: flex;
	align-items: center;
	padding: 0 0 0 12px;
	font-size: 15px;
	font-weight: 500;
	height: 27px;
	width: 93%;
	/* border: solid thin white; */
}

.personal-details-btns {
	display: flex;
	/* justify-content: space-between; */
	justify-content: flex-end;
	align-items: center;
	font-size: 15px;
	height: 27px;
	width: 7%;
	/* border: solid thin white; */
}

/* .personal-details-btn {
	width: 35%;
} */

/* .personal-details-btn:hover {
	transform: scale(1.01);
} */

.personal-details-btns i {
	color: #b39895;
	background: white;
	height: 27px;
	width: 27px;
	font-size: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin: 0;
	padding: 0;
	/* border: solid thin white; */
}

.personal-details-btns i:hover {
	transform: scale(1.07);
}

.personal-details-btns a {
	text-decoration: inherit;
	color: inherit;
}

.personal-details-bottom-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-left: solid thin white;
	width: fit-content;
	min-width: 23%;
	height: 27px;
}
.personal-details-bottom-item i {
	margin: 0 7px 0 12px;
}

.personal-details tr:hover {
	background: none;
}
.personal-details table {
	font-size: 14px;
	width: 100%;
	border-spacing: 10px 0px;
	table-layout: auto;
}
.personal-details th,
.personal-details td {
	border: none;
	border-left: thin solid rgb(255, 255, 255);
	text-align: left;
	background: none;
	padding: 4px 4px 4px 12px;
	color: rgb(255, 255, 255);
}
.personal-details .firstname_th {
	font-size: 15px;
	font-weight: 500;
	border: none;
	height: 26px;
}
.personal-details th {
	font-weight: 600;
	padding: 0 0 0 12px;
}
.personal-details .empty_tr {
	height: 3px;
}

th.edit_btn {
	border: none;
	text-align: right;
}
th.edit_btn a {
	color: inherit; /* blue colors for links too */
	text-decoration: inherit; /* no underline */
}
th.edit_btn i {
	font-size: 18px;
	padding: 0 10px 0 10px;
}
.personal-details td i {
	margin-right: 7px;
}

/*************************************************************************************************/

.reports_div {
	position: fixed;
	left: 160px;
	top: 140px;
	right: 36%;
	bottom: 0;
	padding: 12px 12px 12px 10px;
	/* border: thin solid rgb(86, 109, 124); */
}

.profile_div .naslov {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-size: 14px;
	font-weight: 600;
	text-align: left;
	margin: 0;
	height: 34px;
	padding: 0px 6px 0 10px;
	border: thin solid rgb(190, 190, 190);
	border-radius: 3px;
	color: white;
	color: rgb(110, 110, 110);
}

.profile_div .naslov i {
	margin-right: 5px;
}

.profile_div .naslov a {
	color: white;
	text-decoration: inherit;
}
.profile_div .naslov-btn i {
	height: 27px;
	width: 27px;
	background: rgb(110, 110, 110);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 13px;
	/* margin: 12px 6px; */
	margin: 0;
	color: white;
	cursor: pointer;
	border-radius: 50%;
}

.profile_div .naslov-btn i:hover {
	transform: scale(1.07);
}

.reports-body {
	position: fixed;
	top: 191px;
	left: 160px;
	right: 36%;
	bottom: 10px;
	padding: 0 5px 10px 11px;
	overflow-y: scroll;
}

.appointment-card {
	background: rgb(110, 110, 110);
	color: white;
	border: thin solid rgb(110, 110, 110);
	border-radius: 3px;
	margin: 5px 0;
}
.appointment-card:hover {
	/* transform: scale(1.004); */
	background: rgb(126, 126, 126);
	border: thin solid rgb(126, 126, 126);
}
.appointment-card.active {
	background: rgb(255, 255, 255);
	color: rgb(90, 90, 90);
	border: thin solid rgb(150, 150, 150);
	/* margin: 10px 0 10px 0; */
}
.appointment-card.active:hover {
	transform: none;
	background: white;
	border: thin solid rgb(110, 110, 110);
}

.appointment-card-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	font-weight: 500;
	cursor: pointer;
}

.appointment-card-header-item,
.treatment-list-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 25%;
	/* border: thin solid rgb(255, 255, 255); */
	min-height: 40px;
	padding-right: 5px;
}

.treatment-list-item {
	width: 100%;
	border: none;
	min-height: 38px;
}

.date-item {
	width: 20%;
}
.person-item {
	width: 30%;
}
.location-item {
	width: 21%;
}

.appointment-card-header-item.treatment-list > div:nth-child(n + 2) {
	min-height: 15px;
	max-height: fit-content;
	margin-bottom: 11px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	/* border: thin solid rgb(255, 255, 255); */
}
.appointment-card-header-item.treatment-list {
	display: flex;
	flex-direction: column;
}
.appointment-card-header-item i {
	margin: 0 8px 0 10px;
	font-size: 14px;
}
.treatment-list-item i {
	font-size: 12px;
}
.appointment-card-header-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin: 4px;
	font-size: 13px;
	border-radius: 50%;
	transform: rotate(90deg);
	transition: 300ms ease-out;
}

.appointment-card.active .appointment-card-header-btn {
	color: rgb(90, 90, 90);
	transform: rotate(180deg);
	transition: 300ms ease-out;
}

.appointment-card-body {
	display: none;
}

.appointment-card.active .appointment-card-body {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	/* animation: active 1s ease-out; */
}
/* @keyframes active {
	0% {
		height: 0px;
		opacity: 0;
	}
	100% {
		height: fit-content;
		opacity: 1;
	}
} */
/* .appointment-card-body {
	display: none;
	height: 0;
	transition: 1s ease-in-out;
} */

.appointment-card-body-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 5px 0 0 0;
}
.appointment-card-body-item-label {
	font-weight: 500;
	width: 18%;
	padding: 8px 8px 8px 10px;
}
.appointment-card-body-item-content {
	width: 82%;
	padding: 8px 10px 8px 0px;
}
.appointment-card-body-btns {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin: 20px 0px 0;
	/* border: solid thin gray; */
}
.appointment-card-body-btns-item {
	width: 10%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 15px;
	/* border: solid thin gray; */
}
#profile-btn.appointment-card-body-btns-item {
	width: 70%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
}
#profile-btn.appointment-card-body-btns-item i {
	margin: 10px 6px;
}
.appointment-card-body-btns-item a {
	color: white; /* bluecolors for links too */
	text-decoration: inherit; /* no underline */
	background: rgb(110, 110, 110);
	padding: 0 6px 0 0;
	margin: 0 15px;
	height: 27px;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.appointment-card-body-btns-item a:hover {
	transform: scale(1.03);
}

.appointment-card-body-btns-item .fa-user {
	font-size: 13px;
	background: none;
	height: fit-content;
	width: 13px;
	/* margin: 15px 6px 10px 5px; */
	color: rgb(255, 255, 255);
}
/* .fa-user {
	font-weight: 200;
} */
.appointment-card-body-btns-item i {
	height: 27px;
	width: 27px;
	background: rgb(110, 110, 110);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 13px;
	margin: 15px 0px;
	color: white;
	cursor: pointer;
	border-radius: 50%;
}

.appointment-card-body-btns-item i:hover {
	transform: scale(1.07);
}

/*************************************************************************************************/

.photo-album_div {
	position: fixed;
	top: 140px;
	right: 4px;
	bottom: 0;
	width: 34.5%;
	padding: 12px 10px 12px 12px;
}

.title-div {
	background: rgb(155, 155, 155);
	height: 31px;
	display: flex;
	border-radius: 3px;
}
.title-div-item {
	width: 20%;
	display: flex;
	align-items: center;
	color: white;
	font-weight: 400;
	font-size: 14px;
}

.title-div-item i {
	margin: 0 8px 0 10px;
}

.photo-album {
	width: 34.5%;
	position: fixed;
	top: 192px;
	right: 3px;
	bottom: 10px;
	padding: 0 0px 10px 10px;
	display: flex;
	justify-content: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;
	overflow-y: scroll;
	overflow-x: hidden;
}

.no-info_div {
	width: 100%;
	height: 100%;
	text-align: center;
	padding-top: 100px;
}
.doc a {
	background: #766a76;
	color: white;
	text-decoration: inherit;
	font-size: 14px;
	padding: 3px 8px 4px 8px;
}
.doc a:hover {
	background: #9d949d;
}

.pat a {
	background: #997f7c;
	color: white;
	text-decoration: inherit;
	font-size: 14px;
	padding: 3px 8px 4px 8px;
}
.pat a:hover {
	background: #c4a39f;
}

.photo-album .img_div {
	width: 33.33%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 2px 2px 7px 2px;
}

.image-info {
	width: 100%;
	height: 15px;
	color: white;
	font-size: 8px;
	background: rgb(180, 180, 180);
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 6px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	border-top: thin solid rgb(180, 180, 180);
	border-left: thin solid rgb(180, 180, 180);
	border-right: thin solid rgb(180, 180, 180);
}

.overlay {
	position: absolute;
	z-index: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	font-weight: 400;
	font-size: 13px;
	top: 17px;
	left: 2px;
	right: 2px;
	padding: 0 1px;
	color: rgb(110, 110, 110);
	background-color: rgb(255, 255, 255);
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	border-left: thin solid rgb(180, 180, 180);
	border-right: thin solid rgb(180, 180, 180);
	overflow: hidden;
	height: 0;
	transition: 0.5s ease;
}
.overlay span {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.overlay i {
	width: 23px;
	height: 23px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 11px;
}
.img_div img {
	width: 100%;
	cursor: pointer;
	height: auto;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.image-info:hover + .overlay {
	height: 80px;
	border-bottom: thin solid rgb(180, 180, 180);
}
.overlay:hover {
	height: 80px;
}
.lightbox-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}
.lightbox-img-thumbnail {
	padding: 2px !important;
	margin: 0 !important;
	box-shadow: none !important;
	border-radius: 4px !important;
}
.photo-album img {
	animation: display 0.4s ease-out;
}

@keyframes display {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.lightbox-title-content {
	background: white !important;
}
.lightbox-title-content > div {
	color: gray;
}
/* .lightbox-btn-close {
	right: 20px !important;
	left: auto !important;
} */

/*************************************************************************************************/

.image-update_div .photo-album_div {
	position: unset;
	width: 100%;
	padding: 10px 0;
	margin-left: 3px;
	border-bottom: solid thin rgb(155, 155, 155);
}

.image-update_div .photo-album {
	position: unset;
	width: 100%;
	height: fit-content;
	padding: 0;
	display: flex;
	justify-content: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;
	overflow-y: hidden;
	margin-top: 10px;
}

.image-update_div .naslov {
	width: 100%;
	font-size: 14px;
	font-weight: 400;
	text-align: left;
	margin: 0;
	border-radius: 3px;
	color: white;
	background: rgb(155, 155, 155);
}
.image-update_div .naslov td {
	padding: 2px 0 2px 8px;
	border: none;
}

.image-update_div .naslov td i {
	margin-right: 8px;
}

.image-update_div .naslov tr:hover {
	background: none;
}

.image-update_div .form-container.images {
	padding: 60px 20px;
}
.image-update_div .form-container.images .form-group {
	width: 950px;
}

.image-update_div .no-info_div {
	width: 100%;
	height: 100%;
	text-align: center;
	padding-top: 0px;
}

/*************************************************************************************************/

.print-layout {
	height: 280mm;
	width: 192mm;
	background: rgb(255, 255, 255);
}

.print-header {
	width: 100%;
	height: 15%;
	background: rgb(255, 255, 255);
	display: flex;
	justify-content: space-between;
}

.print-logo {
	padding-top: 30px;
	width: 24%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.print-logo img {
	width: 90%;
	height: auto;
}

.print-address {
	padding: 30px 7px;
	width: 60%;
	text-align: right;
}

.print-content {
	background: rgb(255, 255, 255);
	height: 80%;
	display: flex;
	flex-direction: column;
	/* justify-content: flex-start; */
	justify-content: space-evenly;
}

.print-patient {
	width: 100%;
	height: 10%;
	background: rgb(255, 255, 255);
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	/* border: solid thin gray; */
}

.print-name {
	padding: 10px;
}

.print-patient-address.hdn {
	display: none;
}

.print-notes {
	width: 100%;
	background: rgb(255, 255, 255);
	/* border: solid thin gray; */
}

.print-note {
	width: 100%;
	padding: 10px;
}

.print-note.hdn {
	display: none;
}

.print-note ul {
	list-style-position: inside;
	list-style-type: circle;
}

.print-note li {
	padding-left: 10px;
}

.print-footer {
	width: 100%;
	height: 15%;
	background: rgb(255, 255, 255);
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	/* border: solid thin gray; */
}

.print-date {
	height: 50%;
	padding: 0 10px;
}

.print-doctor {
	height: 50%;
	padding: 0 20px;
}

.bold-span {
	font-weight: 500;
}
.bold-span.hdn {
	display: none;
}

@page {
	size: auto;
	margin: 0mm;
}

/*************************************************************************************************/

/* @media (min-width: 30em) {
	.filepond--item {
		width: calc(33.33% - 0.3em);
	}
}

@media (min-width: 50em) {
	.filepond--item {
		width: calc(33.33% - 0.3em);
	}
} */
